import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { ThemeSwitcherProvider, useThemeSwitcher } from "react-css-theme-switcher";

import manifest from "./antd/manifest.json";

import type { SiderTheme } from "antd/lib/layout/Sider";

/**
 * Available themes.
 */
export enum Theme {
  LIGHT = "LIGHT",
  DARK = "DARK",
}

const THEME_KEY = "theme"; // key for local storage of current theme
const DEFAULT_THEME = Theme.LIGHT; // default theme if now has been selected

/**
 * A list of available themes and their associated metadata; includes the theme enum value, a
 * user-facing label, and the accompanying stylesheet.
 */
export const THEMES = [
  {
    value: Theme.LIGHT,
    label: "Light Theme",
    stylesheet: `/styles/${manifest["light-theme.less"]}`,
  },
  {
    value: Theme.DARK,
    label: "Dark Theme",
    stylesheet: `/styles/${manifest["dark-theme.less"]}`,
  },
];

/**
 * Map of theme to stylesheet, for use with react-css-theme-switcher
 */
const THEME_MAP = THEMES.reduce<{ [k: string]: string }>((themeMap, { value, stylesheet }) => {
  themeMap[value] = stylesheet;
  return themeMap;
}, {});

/**
 * App-level theme context, intended to abstract the internals of switching themes across various
 * design systems (ie. antd and tailwind)
 */
export interface ThemeContext {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const themeContext = React.createContext<ThemeContext>({ theme: Theme.LIGHT, setTheme: () => {} });

/**
 * Combined provider for theme context; should wrap the app's top-most UI component.
 */
export default function ThemeProvider(props: React.PropsWithChildren) {
  return (
    <ThemeSwitcherProvider themeMap={THEME_MAP}>
      <WrappedThemeProvider>{props.children}</WrappedThemeProvider>
    </ThemeSwitcherProvider>
  );
}

/**
 * Wrapped theme provider that has access to all the required context for managing switching for all
 * our design systems.
 */
function WrappedThemeProvider(props: React.PropsWithChildren) {
  const [theme, setTheme] = useState<Theme | null>(null);
  const { switcher } = useThemeSwitcher();

  useEffect(() => {
    // Load theme only once window is loaded, otherwise localStorage is undefined
    setTheme(loadSavedTheme());
  }, [setTheme]);

  const setThemeHelper = useCallback(
    (theme: Theme) => {
      switcher({ theme });
      updateSavedTheme(theme);
      updateDOMMetadata();
      setTheme(theme);
    },
    [switcher]
  );

  // initialize antd theme/apply DOM metadata
  useEffect(() => {
    // Set theme once it's loaded
    if (theme != null) {
      switcher({ theme });
      updateDOMMetadata();
    }
  }, [switcher, theme]);

  return theme != null ? (
    <themeContext.Provider value={{ theme, setTheme: setThemeHelper }}>
      {props.children}
    </themeContext.Provider>
  ) : null;
}

/**
 * Retrieve the theme context.
 */
export function useTheme(): ThemeContext {
  return useContext(themeContext);
}

/**
 * Get the Antd sider theme to use based on the viewer's selected theme.
 */
export function useAntdSiderTheme(): SiderTheme {
  const { theme } = useTheme();
  return theme === Theme.DARK ? "dark" : "light";
}

/**
 * Load the last used theme from local storage, falling back to the default theme as needed.
 */
function loadSavedTheme(): Theme {
  const savedTheme = localStorage.getItem(THEME_KEY) ?? "";
  if (savedTheme in Theme) {
    return savedTheme as Theme;
  } else {
    updateSavedTheme(DEFAULT_THEME);
    return DEFAULT_THEME;
  }
}

/**
 * Update the theme saved in local storage; will also update DOM metadata.
 */
function updateSavedTheme(theme: Theme) {
  localStorage.setItem(THEME_KEY, theme);
}

/**
 * Updates DOM metadata to ensure the appropriate themes are provided.
 */
function updateDOMMetadata() {
  const theme = loadSavedTheme();
  document.documentElement.setAttribute("data-theme", theme.toLowerCase());
}
